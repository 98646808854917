import React from "react";

const Information = () => {
  return (
    <div className="info-container">
      <p>
        <b>INSTRUCTIONS TO CANDIDATES</b>
      </p>
      <ul>
        <li>
          Answer <b>all</b> questions.
        </li>
        <li>You can change your answers at any time during the test.</li>
      </ul>
      <p>
        <b>INFORMATION FOR CANDIDATES</b>
      </p>
      <ul>
        <li>There are 40 questions in this test.</li>
        <li>Each question carries one mark.</li>
        <li>
          The test clock will show you when there are 10 minutes and 5 minutes
          remaining.
        </li>
      </ul>
    </div>
  );
};

export default Information;
