import React from "react";

const HideButtonPopup = ({ toggleHidePopup }) => {
  return (
    <div
      className="modal fade show"
      id="hide-screen-modal"
      tabIndex="-1"
      role="dialog"
      style={{ paddingRight: "17px", display: "block" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="hide-screen-modal-title">
              Screen hidden
            </h5>
          </div>
          <div className="modal-body">
            <p>Your answers have been stored.</p>
            <p>
              Please note that the clock is still running. The time has not been
              paused.
            </p>
            <p>If you wish to leave the room, please tell your invigilator.</p>
            <p>Click the button bellow to go back to your test.</p>
            <button
              type="button"
              className="btn btn-primary bc-button"
              onClick={toggleHidePopup}
              style={{ width: "auto" }}
            >
              Resume test
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HideButtonPopup;
