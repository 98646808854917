import React from "react";
import HtmlReactParser from "html-react-parser";
import DropBoxEmpty from "../../components/DropBoxEmpty";

const DropQuestion = ({
  questions,
  preContent,
  postContent,
  className,
  toggleQuestion,
  chosenQuestion,
  checkAnsweredQuestion,
  generateReplacedText,
  storeAnswers,
  storedAnswers,
}) => {
  return (
    <div className={className}>
      {HtmlReactParser(
        `${preContent ?? ""}${questions
          .map(
            (question) =>
              `${question.question}<question data-input="${question.id}">${question.id}</question>`
          )
          .join("")}${postContent ?? ""}`,
        {
          replace: (domhandlerNode) => {
            if (domhandlerNode.name === "question") {
              return (
                <DropBoxEmpty
                  id={parseInt(domhandlerNode.attribs["data-input"])}
                  toggleQuestion={toggleQuestion}
                  chosenQuestionId={chosenQuestion}
                  checkAnsweredQuestion={checkAnsweredQuestion}
                  generateReplacedText={generateReplacedText}
                  storeAnswers={storeAnswers}
                  storedAnswers={storedAnswers}
                />
              );
            }
          },
        }
      )}
    </div>
  );
};

export default DropQuestion;
