import React, { useState } from "react";
import TestPage from "./TestPage";

const TestPages = ({
  testPages,
  pageNumber,
  pageType,
  testType,
  candidate,
  renderNextPage,
  renderPreviousPage,
  TestsPageJump,
  //
  storedAnswers,
  setStoredAnswers,
  writingAnswerNote,
  setWritingAnswerNote,
}) => {
  const [chosenQuestion, setChosenQuestion] = useState(1);
  const [answeredQuestion, setAnsweredQuestion] = useState([]);
  const [reviewChecked, setReviewChecked] = useState(false);
  const [reviewModeNode, setReviewModeNode] = useState([]);
  const [chooseQForCheckbox, setChooseQForCheckbox] = useState(5);

  const currentPageIndex = pageNumber - 4;
  return testPages.map((testPage, index) => (
    <TestPage
      key={index}
      display={pageType === "Tests" && index === currentPageIndex}
      pageSeq={index + 1}
      pages={testPages}
      page={testPage}
      testType={testType}
      chosenQuestion={chosenQuestion}
      setChosenQuestion={setChosenQuestion}
      answeredQuestion={answeredQuestion}
      setAnsweredQuestion={setAnsweredQuestion}
      renderNextPage={renderNextPage}
      renderPreviousPage={renderPreviousPage}
      TestsPageJump={TestsPageJump}
      reviewChecked={reviewChecked}
      setReviewChecked={setReviewChecked}
      reviewModeNode={reviewModeNode}
      setReviewModeNode={setReviewModeNode}
      chooseQForCheckbox={chooseQForCheckbox}
      setChooseQForCheckbox={setChooseQForCheckbox}
      storedAnswers={storedAnswers}
      setStoredAnswers={setStoredAnswers}
      writingAnswerNote={writingAnswerNote}
      setWritingAnswerNote={setWritingAnswerNote}
    />
  ));
};

export default TestPages;
