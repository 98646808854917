import React from "react";
import HtmlReactParser from "html-react-parser";
import TextInput from "./TextInput";

const MultiInputsInAny = ({
  className,
  preContent,
  content,
  postContent,
  toggleQuestion,
  chosenQuestionId,
  checkAnsweredQuestion,
  storeAnswers,
  storedAnswers,
}) => {
  return (
    <div className={`multiple-inputs-container ${className}`}>
      {HtmlReactParser(
        `${preContent ?? ""}${content
          .map(
            (p) =>
              `${p.answers[0]}<question data-input="${p.id}">${
                p.id
              }</question>${p.answers[1] ? p.answers.slice(1).join("") : ""}`
          )
          .join("")}${postContent ?? ""}`,
        {
          replace: (domhandlerNode) => {
            if (domhandlerNode.name === "question") {
              return (
                <TextInput
                  id={parseInt(domhandlerNode.attribs["data-input"])}
                  toggleQuestion={toggleQuestion}
                  chosenQuestionId={chosenQuestionId}
                  checkAnsweredQuestion={checkAnsweredQuestion}
                  storeAnswers={storeAnswers}
                  storedAnswers={storedAnswers}
                />
              );
            }
          },
        }
      )}
    </div>
  );
};

export default MultiInputsInAny;
