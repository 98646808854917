import React, { Component } from "react";

class GroupInputs extends Component {
  state = {
    answerChecked: [],
  };

  componentDidMount() {
    const storedAnswers = this.props.storedAnswers.filter(
      (answer) => answer.questionId === this.props.questionId
    );
    if (storedAnswers.length > 0) {
      this.setState({ answerChecked: storedAnswers[0].answer });
    } else {
      const answerChecked = [];
      for (let i = 0; i < this.props.answers.length; i++) {
        answerChecked.push(false);
      }
      this.setState({ answerChecked });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.storedAnswers !== this.props.storedAnswers) {
      const storedAnswers = nextProps.storedAnswers.filter(
        (answer) => answer.questionId === this.props.questionId
      );
      if (storedAnswers.length > 0) {
        this.setState({ answerChecked: storedAnswers[0].answer });
      }
    }

    if (nextProps.answers !== this.props.answers) {
      const answerChecked = [];
      for (let i = 0; i < this.props.answers.length; i++) {
        answerChecked.push(false);
      }
      this.setState({ answerChecked });
    }
  }

  handleChange(id) {
    const answerChecked = [...this.state.answerChecked];
    const checkedNumber = this.state.answerChecked.filter((x) => x === true);

    if (this.props.answerType === "one answer") {
      for (let i = 0; i < this.state.answerChecked.length; i++) {
        answerChecked[i] = false;
      }
      answerChecked[id - 1] = !this.state.answerChecked[id - 1];
    } else {
      const answerAmount = parseInt(this.props.answerType.split(" ")[0]);
      if (checkedNumber.length < answerAmount) {
        answerChecked[id - 1] = !this.state.answerChecked[id - 1];
      } else {
        answerChecked[id - 1] = false;
      }
    }

    this.setState({ answerChecked }, () => {
      this.props.checkAnsweredQuestion(
        this.props.questionId,
        this.state.answerChecked
      );
      this.props.storeAnswers(this.props.questionId, this.state.answerChecked);
    });
  }

  render() {
    const { answers, answerType } = this.props;
    return (
      <ul className="answers">
        {answers.map((answer) => (
          <li key={answer.id} onClick={() => this.handleChange(answer.id)}>
            <input
              type={answerType === "one answer" ? "radio" : "checkbox"}
              value={answer.content}
              onChange={() => this.handleChange(answer.id)}
              checked={this.state.answerChecked[answer.id - 1] ? true : false}
            />
            <p>{answer.content}</p>
          </li>
        ))}
      </ul>
    );
  }
}

export default GroupInputs;
