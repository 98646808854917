import React, { Component, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import MultiChoices from "./MultiChoices";
import MultiInputs from "./MultiInputs";
import DropContents from "./DropContents";
import MultiInputInPra from "./MultiInputInPra";
import MultiInputsInImage from "./MultiInputsInImage";
import MultiInputsInAny from "./MultiInputsInAny";
import TableInputs from "./TableInputs";
import TextArea from "./TextArea";
import TextInput from "./TextInput";

class Answers extends Component {
  renderAnswerContent() {
    if (this.props.answers.type === "table inputs two") {
      return (
        <div className={this.props.answers.className}>
          <TableInputs
            key="1"
            content={this.props.answers.content}
            answersIndex={this.props.answers.answersIndex}
            toggleQuestion={this.props.toggleQuestion}
            chosenQuestionId={this.props.chosenQuestion}
            checkAnsweredQuestion={this.props.checkAnsweredQuestion}
            storeAnswers={this.props.storeAnswers}
            storedAnswers={this.props.storedAnswers}
          />
          <div className="static-table-container">
            <table>
              <tbody>{ReactHtmlParser(this.props.answers.staticTable)}</tbody>
            </table>
          </div>
        </div>
      );
    } else if (this.props.answers.type === "table inputs with image") {
      const imageStyle = this.props.answers.imageStyle;
      return (
        <div className={this.props.answers.className}>
          <div className="image-container" style={imageStyle}></div>
          <TableInputs
            key="2"
            content={this.props.answers.content}
            answersIndex={this.props.answers.answersIndex}
            toggleQuestion={this.props.toggleQuestion}
            chosenQuestionId={this.props.chosenQuestion}
            checkAnsweredQuestion={this.props.checkAnsweredQuestion}
            storeAnswers={this.props.storeAnswers}
            storedAnswers={this.props.storedAnswers}
          />
        </div>
      );
    } else if (this.props.answers.type === "inputTexts in paragraphs") {
      return (
        <MultiInputs
          content={this.props.answers.content}
          number={this.props.answers.number}
          className={this.props.answers.className}
          toggleQuestion={this.props.toggleQuestion}
          chosenQuestionId={this.props.chosenQuestion}
          checkAnsweredQuestion={this.props.checkAnsweredQuestion}
          storeAnswers={this.props.storeAnswers}
          storedAnswers={this.props.storedAnswers}
        />
      );
    } else if (this.props.answers.type === "inputTexts in one ul") {
      return (
        <div
          className={`multiple-inputs-container ${this.props.answers.className}`}
        >
          {this.props.answers.content.map((c) => (
            <div key={c.id}>
              {ReactHtmlParser(c.answers[0])}
              <TextInput
                id={c.id}
                toggleQuestion={this.props.toggleQuestion}
                chosenQuestionId={this.props.chosenQuestion}
                checkAnsweredQuestion={this.props.checkAnsweredQuestion}
                storeAnswers={this.props.storeAnswers}
                storedAnswers={this.props.storedAnswers}
              />
              {ReactHtmlParser(c.answers[1])}
            </div>
          ))}
        </div>
      );
    } else if (this.props.answers.type === "inputTexts in one paragraph") {
      return (
        <MultiInputInPra
          content={this.props.answers.content}
          number={this.props.answers.number}
          className={this.props.answers.className}
          toggleQuestion={this.props.toggleQuestion}
          chosenQuestionId={this.props.chosenQuestion}
          checkAnsweredQuestion={this.props.checkAnsweredQuestion}
          storeAnswers={this.props.storeAnswers}
          storedAnswers={this.props.storedAnswers}
        />
      );
      // 以上應該都用不到了
    } else if (this.props.answers.type === "radios") {
      return (
        <MultiChoices
          content={this.props.answers.content}
          toggleQuestion={this.props.toggleQuestion}
          chosenQuestionId={this.props.chosenQuestion}
          checkAnsweredQuestion={this.props.checkAnsweredQuestion}
          storeAnswers={this.props.storeAnswers}
          storedAnswers={this.props.storedAnswers}
        />
      );
    } else if (this.props.answers.type === "checkboxes") {
      return (
        <MultiChoices
          content={this.props.answers.content}
          toggleQuestion={this.props.toggleQuestion}
          chosenQuestionId={this.props.chosenQuestion}
          checkAnsweredQuestion={this.props.checkAnsweredQuestion}
          storeAnswers={this.props.storeAnswers}
          storedAnswers={this.props.storedAnswers}
        />
      );
    } else if (this.props.answers.type === "inputTexts in an image") {
      return (
        <MultiInputsInImage
          src={this.props.answers.src}
          content={this.props.answers.content}
          number={this.props.answers.number}
          className={this.props.answers.className}
          style={this.props.answers.style}
          toggleQuestion={this.props.toggleQuestion}
          chosenQuestionId={this.props.chosenQuestion}
          checkAnsweredQuestion={this.props.checkAnsweredQuestion}
          storeAnswers={this.props.storeAnswers}
          storedAnswers={this.props.storedAnswers}
        />
      );
    } else if (this.props.answers.type === "inputTexts in any") {
      return (
        <Fragment>
          <MultiInputsInAny
            preContent={this.props.answers.preContent}
            content={this.props.answers.content}
            postContent={this.props.answers.postContent}
            number={this.props.answers.number}
            className={this.props.answers.className}
            style={this.props.answers.style}
            toggleQuestion={this.props.toggleQuestion}
            chosenQuestionId={this.props.chosenQuestion}
            checkAnsweredQuestion={this.props.checkAnsweredQuestion}
            storeAnswers={this.props.storeAnswers}
            storedAnswers={this.props.storedAnswers}
          />
        </Fragment>
      );
    } else if (this.props.answers.type === "table inputs") {
      return (
        <div className={this.props.answers.className}>
          <TableInputs
            key="2"
            isListenDiagramLabeling={
              this.props.answers.className.indexOf("listening") >= 0
            }
            preContent={this.props.answers.preContent}
            content={this.props.answers.content}
            postContent={this.props.answers.postContent}
            answersIndex={this.props.answers.answersIndex}
            toggleQuestion={this.props.toggleQuestion}
            chosenQuestionId={this.props.chosenQuestion}
            checkAnsweredQuestion={this.props.checkAnsweredQuestion}
            storeAnswers={this.props.storeAnswers}
            storedAnswers={this.props.storedAnswers}
          />
        </div>
      );
    } else if (this.props.answers.type === "dragNDrop") {
      return (
        <DropContents
          content={this.props.answers.content}
          postContent={this.props.answers.postContent}
          number={this.props.answers.number}
          className={this.props.answers.className}
          toggleQuestion={this.props.toggleQuestion}
          chosenQuestionId={this.props.chosenQuestion}
          checkAnsweredQuestion={this.props.checkAnsweredQuestion}
          dragItems={this.props.answers.dragItems}
          generateReplacedText={this.props.generateReplacedText}
          replacedText={this.props.replacedText}
          storeAnswers={this.props.storeAnswers}
          storedAnswers={this.props.storedAnswers}
        />
      );
    } else if (this.props.answers.type === "writing") {
      return (
        <TextArea
          checkAnsweredQuestion={this.props.checkAnsweredQuestion}
          writingAnswerNote={this.props.writingAnswerNote}
          updateAnwerPerPage={this.props.updateAnwerPerPage}
          pageNumber={this.props.pageNumber}
        />
      );
    } else {
      return <div>{ReactHtmlParser(this.props.answers.content)}</div>;
    }
  }

  render() {
    const { question, description, title, subTitle } = this.props.answers;
    return (
      <div>
        {question && <h4>{question}</h4>}
        {description && description}
        {title && <h3>{ReactHtmlParser(title)}</h3>}
        {subTitle && ReactHtmlParser(subTitle)}
        {this.renderAnswerContent()}
      </div>
    );
  }
}

export default Answers;
