import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import GroupInputs from "./GroupInputs";

class MultiChoices extends Component {
  render() {
    const {
      content,
      toggleQuestion,
      chosenQuestionId,
      checkAnsweredQuestion,
      storeAnswers,
      storedAnswers,
    } = this.props;

    return (
      <div className="multiple-choice-container">
        {content.map((question) => (
          <div
            key={question.id}
            className={chosenQuestionId === question.id ? "chosen" : ""}
          >
            <div
              className="question"
              onClick={() => toggleQuestion(question.id)}
            >
              {ReactHtmlParser(question.question)}
            </div>
            <GroupInputs
              answers={question.answers}
              answerType={
                question.ids.length === 1
                  ? "one answer"
                  : `${question.ids[1] - question.ids[0] + 1} answers`
              }
              questionId={question.id}
              checkAnsweredQuestion={checkAnsweredQuestion}
              storeAnswers={storeAnswers}
              storedAnswers={storedAnswers}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default MultiChoices;
