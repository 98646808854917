import React, { Fragment, useState } from "react";
import Logo from "./assets/white-logo-noword.svg";
import Timer from "../components/Timer";
import AudioPlay from "../components/AudioPlay";
import HideButtonPopup from "../components/staticComponents/HideButtonPopup";
import HelpButtonPopup from "../components/staticComponents/HelpButtonPopup";

const Banner = ({
  candidate,
  pageType,
  testType,
  pageNumber,
  testTime,
  playTestSound,
  audioPath,
  forceEndTest,
}) => {
  const [showHidePopup, setShowHidePopup] = useState(false);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [volumeRange, setVolumeRange] = useState(0.5);

  const toggleHidePopup = () => {
    setShowHidePopup(!showHidePopup);
  };

  const toggleHelpPopup = () => {
    setShowHelpPopup(!showHelpPopup);
  };

  let helpContainerClass =
    pageType === "End" ? "help-container end-page" : "help-container";
  if (testType === "L") {
    helpContainerClass += " listening-test";
  }

  const getCandidateNameDisplay = () => {
    if (!candidate.name_ch) {
      return candidate.name;
    }

    return `${candidate.name} / ${candidate.name_ch}`;
  };

  return (
    <div className={`container-fluid top-bar ${helpContainerClass}`}>
      <div className="row">
        <div className="col col-12 col-md-5 student">
          {pageNumber !== 1 && (
            <Fragment>
              <img className="height-control" src="/img/Icon.svg" alt="" />
              <span>{getCandidateNameDisplay()}</span>
            </Fragment>
          )}
        </div>
        {pageType === "Intro" ? (
          <div className="col col-12 col-md-2">
            <img className="height-control" src={Logo} alt="Logo" />
          </div>
        ) : (
          <Timer startingMin={testTime} forceEndTest={forceEndTest} />
        )}
        <div className="col col-12 col-md-5 help">
          <div className="row">
            {testType === "L" && candidate && audioPath && (
              <AudioPlay
                audioPath={audioPath}
                examNumber={candidate.examNumber}
                pageNumber={pageNumber}
                pageType={pageType}
                playTestSound={playTestSound}
                volumeRange={volumeRange}
                setVolumeRange={setVolumeRange}
              />
            )}
            {pageType !== "Intro" && pageType !== "End" && (
              <div className="col col-12 col-md-7 text-center text-md-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggleHelpPopup}
                >
                  Help
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggleHidePopup}
                >
                  Hide
                </button>
              </div>
            )}
            {showHelpPopup && (
              <HelpButtonPopup
                pageNumber={pageNumber - 2}
                toggleHelpPopup={toggleHelpPopup}
                showHelpPopup={showHelpPopup}
              />
            )}
            {showHidePopup && (
              <HideButtonPopup
                toggleHidePopup={toggleHidePopup}
                showHidePopup={showHidePopup}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
