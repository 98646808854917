import React, { useEffect, useRef, useState } from "react";
import sampleAudio from "../assets/audios/sample-audio.mp3";

const AudioPlay = ({
  volumeRange,
  setVolumeRange,
  pageNumber,
  pageType,
  audioPath,
  playTestSound,
}) => {
  const [, setIsTestPlaying] = useState(false);
  const [testStarted, setTestStarted] = useState(false);
  const audioPlayer = useRef(null);
  useEffect(() => {
    if (!audioPlayer || testStarted) {
      return;
    }

    if (pageNumber === 2) {
      audioPlayer.current.src = sampleAudio;
    } else if (pageNumber === 4 && pageType === "Tests") {
      audioPlayer.current.src = audioPath;
      audioPlayer.current.play();
      setTestStarted(true);
    } else if (pageType !== "Tests") {
      audioPlayer.current.pause();
    }
  }, [audioPath, pageNumber, pageType, testStarted]);

  useEffect(() => {
    setIsTestPlaying((pre) => {
      if (pre === playTestSound) {
        return pre;
      }

      if (playTestSound) {
        audioPlayer.current.play();
      } else {
        audioPlayer.current.pause();
      }
      return playTestSound;
    });
  }, [playTestSound]);

  const handleChange = (event) => {
    const newVolume = event.target.value;
    setVolumeRange(newVolume);
    audioPlayer.current.volume = newVolume;
  };

  return (
    <div className="audio-wrapper col col-12 col-md-5 text-center pt-2 pt-md-0">
      <div className="audio-container pb-2 pb-md-0">
        <img
          className="volume-icon height-control"
          src="/img/Speaker.svg"
          alt=""
        />
        <input
          className="slider"
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volumeRange}
          onChange={handleChange}
        />
        <audio ref={audioPlayer} loop={pageNumber === 2} />
      </div>
    </div>
  );
};

export default AudioPlay;
