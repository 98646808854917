import React, { Fragment, useState } from "react";
import ConfirmDetails from "./pageContents/ConfirmDetails";
import Introductions from "./pageContents/Introductions";
import TestSound from "./pageContents/TestSound";
import ConfirmFinish from "./pageContents/ConfirmFinish";
import TestResult from "./pageContents/TestResult";
import { INTROS } from "../assets/content/intros";
import HighlightNotes from "../components/staticComponents/HighlightNotes";
import { find } from "ramda";
import TestPages from "./pageContents/TestPages";

const MainContent = ({
  testPages,
  answers,
  candidate,
  testType,
  pageType,
  pageNumber,
  renderNextPage,
  renderPreviousPage,
  updatePlayTestSound,
  testSoundBtn,
  testEnd,
  startReviewAnswers,
  forceEndTest,
  TestsPageJump,
}) => {
  const [storedAnswers, setStoredAnswers] = useState([]);
  const [writingAnswerNote, setWritingAnswerNote] = useState([]);

  const pageContent = () => {
    if (pageType === "Intro") {
      switch (pageNumber) {
        case 1:
          return (
            <ConfirmDetails
              candidate={candidate}
              handleClick={renderNextPage}
            />
          );
        case 2:
          return (
            <TestSound
              handleClick={renderNextPage}
              updatePlayTestSound={updatePlayTestSound}
              testSoundBtn={testSoundBtn}
            />
          );
        case 3:
          return (
            <Introductions
              introductions={find(
                (intro) => intro.testType === testType,
                INTROS
              )}
              handleClick={renderNextPage}
            />
          );
        default:
          return null;
      }
    } else if (pageType === "Tests") {
      // handle in renderTests
      return <></>;
    } else {
      const typeAnswers = testType === "W" ? writingAnswerNote : storedAnswers;

      if (testEnd) {
        if (testType === "W") {
          return (
            <ConfirmFinish
              candidate={candidate}
              testType={testType}
              answerKey={answers}
              testPages={testPages}
              storedAnswers={typeAnswers}
              handleClick={startReviewAnswers}
              testEnd={testEnd}
              forceEndTest={forceEndTest}
            />
          );
        } else {
          return (
            <TestResult
              candidate={candidate}
              testPages={testPages}
              testType={testType}
              answerKey={answers}
              storedAnswers={typeAnswers}
            />
          );
        }
      } else {
        return (
          <ConfirmFinish
            candidate={candidate}
            testType={testType}
            answerKey={answers}
            testPages={testPages}
            storedAnswers={typeAnswers}
            handleClick={startReviewAnswers}
            testEnd={testEnd}
            forceEndTest={forceEndTest}
          />
        );
      }
    }
  };

  return (
    <div
      className={`main-content-container ${
        pageType === "Tests" ? "full-height" : ""
      }`}
    >
      {!testEnd && (
        <Fragment>
          <HighlightNotes />
          <TestPages
            testPages={testPages}
            pageNumber={pageNumber}
            pageType={pageType}
            testType={testType}
            candidate={candidate}
            renderNextPage={renderNextPage}
            renderPreviousPage={renderPreviousPage}
            TestsPageJump={TestsPageJump}
            //
            storedAnswers={storedAnswers}
            setStoredAnswers={setStoredAnswers}
            writingAnswerNote={writingAnswerNote}
            setWritingAnswerNote={setWritingAnswerNote}
          />
        </Fragment>
      )}
      {pageContent()}
    </div>
  );
};

export default MainContent;
