import React from "react";
import { is } from "ramda";
import { find } from "ramda";
import { findIndex } from "ramda";

export const questionReg = new RegExp(
  /&lt;question&gt;(\d+)&lt;\/question&gt;/,
  "gi"
);
export const questionsReg = new RegExp(
  /&lt;questions&gt;(\d+-\d+)&lt;\/questions&gt;/,
  "gi"
);

export const optionReg = new RegExp(
  /&lt;option&gt;(.*?)&lt;\/option&gt;/,
  "gi"
);

export const parseAnswers = (pages, answers) => {
  const specialAnswers = {};
  pages.forEach((page) => {
    page.groups.forEach((group, groupIndex) => {
      const groupType = group.type;
      const groupTypePreCategory = groupType.split("-")[0];
      if (!["OPTIONS", "TABLE", "DRAG"].includes(groupTypePreCategory)) {
        return;
      }

      if ("OPTIONS-More" === groupType) {
        group.questions.forEach((question) => {
          const questionSeqs = question.seq.split("-");
          const startSeq = parseInt(questionSeqs[0]);
          const endSeq = parseInt(questionSeqs[1]);
          const options = [];
          for (let seq = startSeq; seq <= endSeq; seq++) {
            const answer = find((a) => parseInt(a.seq) === seq, answers);
            if (!answer) {
              continue;
            }

            options.push(parseInt(answer.answer));
          }
          for (let seq = startSeq; seq <= endSeq; seq++) {
            specialAnswers[seq] = {
              questionId: seq,
              type: "checkboxes",
              answer: options,
              startQuestionId: startSeq,
            };
          }
        });
        return;
      }

      if ("OPTIONS" === groupTypePreCategory) {
        group.questions.forEach((question) => {
          const questionSeq = parseInt(question.seq);
          const answer = find((a) => parseInt(a.seq) === questionSeq, answers);
          if (!answer) {
            return;
          }

          const answerInContent = find(
            (c) => c.id === questionSeq,
            page.formattedGroups[groupIndex].content
          );
          if (!answerInContent) {
            return;
          }

          const answersArray = new Array(answerInContent.answers.length).fill(
            false
          );
          answersArray[parseInt(answer.answer) - 1] = true;

          specialAnswers[parseInt(question.seq)] = {
            questionId: parseInt(question.seq),
            type: "radios",
            answer: answersArray,
          };
        });
        return;
      }

      if ("TABLE" === groupTypePreCategory) {
        page.formattedGroups[groupIndex].content.forEach((answerInContent) => {
          const realAnswer = find(
            (a) => a.seq === `${answerInContent.id}`,
            answers
          );
          if (!realAnswer) {
            return;
          }

          // 20220825 Table 改成存選項編號
          specialAnswers[answerInContent.id] = {
            questionId: answerInContent.id,
            type: "table",
            answer: parseInt(realAnswer.answer),
          };
          return;

          // const answerIndex = findIndex(
          //   (a) => a === realAnswer.answer,
          //   page.formattedGroups[groupIndex].answersIndex
          // );
          // specialAnswers[answerInContent.id] = {
          //   questionId: answerInContent.id,
          //   type: "table",
          //   answer: answerIndex + 1,
          // };
        });
      }

      // 20220825 Drag 改成存選項編號
      if ("DRAG-Common" === groupType) {
        const { content: questionList, dragItems } =
          parseListenDragDropQuestion(group);

        questionList.forEach((question) => {
          const realAnswer = find((a) => a.seq === `${question.id}`, answers);
          if (!realAnswer) {
            return;
          }

          specialAnswers[question.id] = {
            questionId: question.id,
            type: "drag",
            answer: dragItems[parseInt(realAnswer.answer) - 1],
          };
          return;
        });
        return;
      }

      // 20220825 Drag 改成存選項編號
      if ("DRAG" === groupTypePreCategory) {
        const questionList = [];
        const groupMatches = group.question_html.matchAll(questionReg);
        for (let match of groupMatches) {
          questionList.push(match[1]);
        }

        const groupMultiMatches = group.question_html.matchAll(questionsReg);
        for (let match of groupMultiMatches) {
          const startend = match[1].split("-");
          for (let i = parseInt(startend[0]); i <= parseInt(startend[1]); i++) {
            questionList.push(`${i}`);
          }
        }

        questionList.forEach((questionId) => {
          const realAnswer = find((a) => a.seq === `${questionId}`, answers);
          if (!realAnswer) {
            return;
          }

          specialAnswers[parseInt(questionId)] = {
            questionId: parseInt(questionId),
            type: "drag",
            answer:
              page.formattedGroups[groupIndex].dragItems[
                parseInt(realAnswer.answer) - 1
              ],
          };
          return;
        });
        return;
      }
    });
  });

  return answers.map((answer) => {
    const answerSeq = parseInt(answer.seq);
    return (
      specialAnswers[answerSeq] ?? {
        questionId: answerSeq,
        type: "input",
        answer: answer.answer.split("||"),
      }
    );
  });
};

/**
 * 新舊題目型態對應
 * 重構
 * table inputs with image: table-inputs-with-image
 */
export const getGroupTypeMapping = (group) => {
  const groupType = group.type;
  const basicMappping = {
    "TEXT_INPUT-Table": {
      type: "inputTexts in any",
      className: "with-bullets-points input-with-paragraph",
    },
    "TEXT_INPUT-Note": {
      type: "inputTexts in any",
      className: "with-bullets-points input-with-paragraph",
    },
    "TEXT_INPUT-Sentence": {
      type: "inputTexts in any",
      className: "with-bullets-points input-with-paragraph",
    },
    "TEXT_INPUT-Summary": {
      type: "inputTexts in any",
      className: "with-bullets-points input-with-paragraph",
    },
    "TEXT_INPUT-ShortAnswer": {
      type: "inputTexts in any",
      className: "with-bullets-points input-with-paragraph",
    },
    "TEXT_INPUT_HOVER-Diagram": {
      type: "inputTexts in an image",
      className: "with-bullets-points input-with-paragraph",
    },
    "TEXT_INPUT_HOVER-FlowChart": {
      type: "inputTexts in an image",
      className: "with-bullets-points input-with-paragraph",
    },
    "OPTIONS-One": { type: "radios", className: "" },
    "OPTIONS-More": { type: "checkboxes", className: "" },
    "OPTIONS-TFN": { type: "radios", className: "" },
    "OPTIONS-YNN": { type: "radios", className: "" },
    "DRAG-Heading": { type: "dragNDrop", className: "big-text-drag" },
    "DRAG-Sentence": { type: "dragNDrop", className: "drop-content-inline" },
    "DRAG-Feature": { type: "dragNDrop", className: "big-text-drag" },
    "DRAG-Summary": { type: "dragNDrop", className: "drop-content-inline" },
    "DRAG-Common": { type: "dragNDrop", className: "listening" }, // 聽力用
    "TABLE-Feature-Matching": {
      type: "table inputs",
      className: "table-inputs",
    },
    "TABLE-Matching": { type: "table inputs", className: "table-inputs" },
    "TABLE-Labelling": {
      type: "table inputs",
      className: "table-inputs listening",
    },
  };

  const data = basicMappping[groupType] ?? {};

  return data;
};

export const parseGroupAnswerContent = (group) => {
  const groupType = group.type;
  const groupTypePreCategory = groupType.split("-")[0];
  if ("TEXT_INPUT_HOVER" === groupTypePreCategory) {
    return parseInputInImageQuestion(group);
  }

  if ("OPTIONS" === groupTypePreCategory) {
    const data = parseOptionQuestion(group);
    if ("OPTIONS-TFN" === groupType) {
      data.content.forEach((singleQuestion) => {
        singleQuestion.answers.push(
          ...[
            { id: 1, content: "TRUE" },
            { id: 2, content: "FALSE" },
            { id: 3, content: "NOT GIVEN" },
          ]
        );
      });
    } else if ("OPTIONS-YNN" === groupType) {
      data.content.forEach((singleQuestion) => {
        singleQuestion.answers.push(
          ...[
            { id: 1, content: "YES" },
            { id: 2, content: "NO" },
            { id: 3, content: "NOT GIVEN" },
          ]
        );
      });
    }
    return data;
  }

  if ("DRAG-Common" === groupType) {
    return parseListenDragDropQuestion(group);
  }

  if ("DRAG" === groupTypePreCategory) {
    return parseDragDropQuestion(group);
  }

  if ("TABLE" === groupTypePreCategory) {
    return parseTableQuestion(group);
  }

  return parseInputQuestion(group);
};

const parseInputInImageQuestion = (group) => {
  const data = {
    style: {
      width: "100%",
      position: "relative",
    },
    src: "",
    preContent: "",
    content: [],
    postContent: "",
  };

  const firstImage = group.question_html.match(/<img [^>]+>/);
  if (!firstImage) {
    return data;
  }

  let questionContent = group.question_html;
  questionContent = questionContent.replaceAll(
    new RegExp(`(<p>)?${firstImage[0]}(</p>)?`, "gi"),
    firstImage[0]
  );
  const splitContent = questionContent.split(firstImage[0]);
  data.preContent = splitContent[0];
  data.postContent = splitContent[1] ?? "";

  data.src = firstImage[0].match(/http[^"]+/)[0];
  data.content = group.questions.map((question) => ({
    id: parseInt(question.seq),
    style: {
      left: `${
        is(Array, question.question_position) && question.question_position[0]
          ? question.question_position[0] * 100
          : 0
      }%`,
      top: `${
        is(Array, question.question_position) && question.question_position[1]
          ? question.question_position[1] * 100
          : 0
      }%`,
      width: `${
        is(Array, question.question_position) && question.question_position[2]
          ? question.question_position[2] * 100
          : 25
      }%`,
      transform: "translateY(-100%)",
      position: "absolute",
    },
  }));

  return data;
};

const parseOptionQuestion = (group) => {
  const content = group.questions.map((question) => {
    const answers = [];
    let answerSeq = 1;
    let questionContent = question.question;
    for (let option of question.options ?? []) {
      answers.push({ id: answerSeq, content: option });
      answerSeq++;
    }

    const questionSeqs = question.seq.split("-");
    const questionSeq = parseInt(questionSeqs[0]);
    const questionWording = `${
      questionSeqs.length === 1
        ? questionSeq
        : `${questionSeq}-${questionSeqs[1]}`
    }`;
    return {
      id: questionSeq,
      ids:
        questionSeqs.length > 1
          ? [questionSeq, parseInt(questionSeqs[1])]
          : [questionSeq],
      question: questionContent.match(/^<p>/)
        ? `<p><b>${questionWording}</b>${questionContent.slice(3)}`
        : `<b>${questionWording}</b>${questionContent}`,
      answers,
    };
  });

  return { content };
};

export const parseListenDragDropQuestion = (group) => {
  const dragItems = (group.options ?? []).map((o) => o);
  const content = (group.questions ?? []).map((q) => ({
    id: parseInt(q.seq),
    answers: [q.question.replace(/^<p>(.*?)<\/p>/, "$1")],
  }));

  return {
    preContent: group.question_html_pre,
    content,
    postContent: group.question_html_post,
    dragItems,
  };
};

export const parseDragDropQuestion = (group) => {
  let questionContent = group.question_html;

  // 文章內的題目交給 Question Parser
  questionContent = questionContent.replaceAll(questionsReg, "");

  const questionMatches = questionContent.matchAll(questionReg);
  const dragItems = [];
  for (let option of group.options ?? []) {
    dragItems.push(option);
  }

  const content = [];
  for (let questionMatch of questionMatches) {
    // 去除 p tag
    questionContent = questionContent.replaceAll(
      new RegExp(`(<p>)?${questionMatch[0]}(</p>)?`, "gi"),
      questionMatch[0]
    );

    const seq = parseInt(questionMatch[1]);
    const splitContent = questionContent.split(questionMatch[0]);
    content.push({
      id: seq,
      answers: [splitContent[0]],
    });

    questionContent = splitContent.slice(1).join("");
  }

  return { content, dragItems, postContent: questionContent };
};

const parseTableQuestion = (group) => {
  const answersIndex = (group.options ?? []).map((o) => o);
  const content = (group.questions ?? []).map((q) => ({
    id: parseInt(q.seq),
    answers: q.question.replace(/^<p>(.*?)<\/p>/, "$1"),
  }));

  return {
    preContent: group.question_html_pre,
    content,
    postContent: group.question_html_post,
    answersIndex,
  };
};

const parseInputQuestion = (group) => {
  let questionContent = group.question_html;

  const questionMatches = questionContent.matchAll(questionReg);
  const content = [];
  for (let questionMatch of questionMatches) {
    // 去除 p tag
    questionContent = questionContent.replaceAll(
      new RegExp(`(<p>)?${questionMatch[0]}(</p>)?`, "gi"),
      questionMatch[0]
    );

    const seq = parseInt(questionMatch[1]);
    const splitContent = questionContent.split(questionMatch[0]);
    content.push({
      id: seq,
      answers: [splitContent[0]],
    });

    questionContent = splitContent.slice(1).join("");
  }
  if (content.length === 0) {
    return { content };
  }
  content[content.length - 1].answers.push(questionContent);

  return { content };
};

export const getGroupHint = (groupType, limit) => {
  // TODO
  // <MenuItem value="DRAG-Heading">Matching Headings</MenuItem>
  // <MenuItem value="DRAG-Sentence">Matching Sentence Endings</MenuItem>
  // <MenuItem value="DRAG-Feature">Matching Features</MenuItem>
  // <MenuItem value="DRAG-Summary">Summary (choose from a list)</MenuItem>
  // <MenuItem value="TABLE-Matching">Matching Information</MenuItem>
  // <MenuItem value="DRAG-Common">Matching(選項多於題目)</MenuItem>
  // <MenuItem value="TABLE-Labelling">Diagram Labelling</MenuItem>

  let hint = "";
  switch (groupType) {
    case "TEXT_INPUT-Table":
      hint = "Complete the table below.";
      break;
    case "TEXT_INPUT-Note":
      hint = "Complete the notes below.";
      break;
    case "TEXT_INPUT-Sentence":
      hint = "Complete the sentences below.";
      break;
    case "TEXT_INPUT-Summary":
      hint = "Complete the summary below.";
      break;
    case "TEXT_INPUT-ShortAnswer":
      hint = "Complete the short answers below.";
      break;
    case "TEXT_INPUT-HOVER-Diagram":
      hint = "Complete the diagram below.";
      break;
    case "OPTIONS-One":
      return <p>Choose the correct answer.</p>;
    case "OPTIONS-More":
      return <p>Choose correct answers.</p>;
    case "OPTIONS-TFN":
      return (
        <p>
          Do the following statements agree with the information given
          <br />
          Choose <br />
          <i>
            <b>TRUE</b> if the statement agrees with the information given in
            the text
            <br />
            <b>FALSE</b> if the statement contradicts the information
            <br />
            <b>NOT GIVEN</b> if there is no information on this.
          </i>
        </p>
      );
    case "OPTIONS-YNN":
      return (
        <p>
          Do the following statements agree with the information given
          <br />
          Choose <br />
          <i>
            <b>YES</b> if the statement agrees with the information given in the
            text
            <br />
            <b>NO</b> if the statement contradicts the information
            <br />
            <b>NOT GIVEN</b> if there is no information on this.
          </i>
        </p>
      );
    default:
      return <></>;
  }

  switch (groupType.split("-")[0]) {
    case "TEXT_INPUT":
      return (
        <p>
          {hint}
          <br />
          Write <b>{limit}</b> from the passage for each answer.
        </p>
      );
    default:
      return <></>;
  }
};
