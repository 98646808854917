import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.scss";
import "./components/components.scss";
import "./containers/containers.scss";
import "./containers/pageContents/pageContent.scss";
import "./components/staticComponents/popups.scss";
import "./components/staticComponents/HighlightNotes.scss";
import Banner from "./containers/Banner";
import MainContent from "./containers/MainContent";
import PasswordInput from "./containers/PasswordInput";
import {
  getGroupHint,
  getGroupTypeMapping,
  parseAnswers,
  parseGroupAnswerContent,
  questionReg,
  questionsReg,
} from "./containers/pageContents/Const";

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [urlKey, setUrlKey] = useState("");
  const [candidate, setCandidate] = useState(null);
  const [examContent, setExamContent] = useState(null);
  const [testPages, setTestPages] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [testType, setTestType] = useState("L");
  const [testTime, setTestTime] = useState(0);
  const [pageType, setPageType] = useState("Intro");
  const [pageNumber, setPageNumber] = useState(1);
  const [playTestSound, setPlayTestSound] = useState(false);
  const [testEnd, setTestEnd] = useState(false);

  // console.log(answers);

  useEffect(() => {
    let path = window.location.pathname;

    const url = process.env.REACT_APP_IS_URLKEY_VALID + path;
    axios.get(`${url}`).then((res) => {
      const data = res.data;
      if (data.success) {
        setUrlKey(data.urlKey);
      } else {
        if (process.env.NODE_ENV === "development") {
          return;
        } else {
          window.location.href = process.env.REACT_APP_HOMEPAGE;
        }
      }
    });
  }, []);

  const mainContent = () => {
    if (isLoaded) {
      return (
        <MainContent
          candidate={candidate}
          testPages={testPages}
          answers={answers}
          testType={testType}
          pageType={pageType}
          pageNumber={pageNumber}
          renderNextPage={renderNextPage}
          renderPreviousPage={renderPreviousPage}
          updatePlayTestSound={updatePlayTestSound}
          testSoundBtn={playTestSound ? "Stop sound" : "Play sound"}
          TestsPageJump={TestsPageJump}
          startReviewAnswers={startReviewAnswers}
          testEnd={testEnd}
          forceEndTest={forceEndTest}
        />
      );
    } else {
      return <PasswordInput handleLoading={handleLoading} urlKey={urlKey} />;
    }
  };

  const handleLoading = (candidate, examContent) => {
    console.log(examContent.pages);
    // console.log(examContent.answers);
    examContent.pages.forEach((page, pageIndex) => {
      const formattedGroups = [];
      if (examContent.test_type === "W") {
        formattedGroups.push({
          type: "writing",
          number: pageIndex + 1,
          content: [
            {
              id: pageIndex + 1,
              answers: [],
            },
          ],
        });
      }

      page.groups.forEach((group) => {
        const questionList = [];
        let questionWording = "";

        const groupMatches = group.question_html.matchAll(questionReg);
        for (let match of groupMatches) {
          questionList.push(match[1]);
        }

        const groupMultiMatches = group.question_html.matchAll(questionsReg);
        for (let match of groupMultiMatches) {
          const startend = match[1].split("-");
          for (let i = parseInt(startend[0]); i <= parseInt(startend[1]); i++) {
            questionList.push(`${i}`);
          }
        }

        group.questions.forEach((question) => {
          if (!question.seq) {
            return;
          }

          if (question.seq.replaceAll(" ", "").match(/^\d+-\d+$/)) {
            const startend = question.seq.split("-");
            for (
              let i = parseInt(startend[0].replaceAll(" ", ""));
              i <= parseInt(startend[1].replaceAll(" ", ""));
              i++
            ) {
              questionList.push(`${i}`);
            }
          } else {
            questionList.push(question.seq);
          }
        });

        if (questionList.length === 0) {
          return;
        }

        questionList.sort((a, b) => a - b);
        questionList.forEach((questionSeq, index) => {
          if (index === 0) {
            questionWording += `${questionSeq}`;
            return;
          }

          if (questionList[index] - questionList[index - 1] === 1) {
            return;
          }
          questionWording += ` - ${questionList[index - 1]}, `;
          questionWording += `${questionList[index]}`;
        });
        if (
          questionWording.slice(
            questionList[questionList.length - 1].length * -1
          ) !== questionList[questionList.length - 1]
        ) {
          questionWording += ` - ${questionList[questionList.length - 1]}`;
        }

        // Adapt to original format
        const groupData = {
          question: `Questions ${questionWording}`,
          description: getGroupHint(group.type, group.question_limit),
          number: questionList.length,
          ...getGroupTypeMapping(group),
          ...parseGroupAnswerContent(group),
        };
        formattedGroups.push(groupData);
      });
      page.page = pageIndex + 1;
      page.formattedGroups = formattedGroups;

      // 題目內的拖拉答題
      const contentMatches = page.content.matchAll(questionReg);
      let pageContent = page.content;
      const parsedContent = [];
      for (let questionMatch of contentMatches) {
        const seq = parseInt(questionMatch[1]);
        const splitContent = pageContent.split(questionMatch[0]);
        parsedContent.push({
          id: seq,
          question: splitContent[0],
        });

        pageContent = splitContent.slice(1).join("");
      }
      if (parsedContent.length === 0) {
        return;
      }
      page.questionType = "drop in answer part";
      page.parsedContent = parsedContent;
      page.postContent = pageContent;
    });

    setCandidate(candidate);
    setExamContent(examContent);
    setTestPages(examContent.pages);
    setAnswers(parseAnswers(examContent.pages, examContent.answers));

    let testType = "L";
    let testTime = 30;
    switch (examContent.test_type) {
      case "L":
        break;
      case "R":
        testType = "R";
        testTime = 60;
        break;
      case "W":
        testType = "W";
        testTime = 60;
        break;
      default:
        throw new Error();
    }
    setTestType(testType);
    setTestTime(testTime);
    setIsLoaded(true);
  };

  const renderNextPage = () => {
    if (pageNumber === 1 && testType !== "L") {
      //skip TestSound component if not listening test
      setPageNumber(pageNumber + 2);
    } else {
      setPageNumber(pageNumber + 1);
    }

    if (pageNumber === 3) {
      setPageType("Tests");
    } else if (pageNumber === testPages.length + 3) {
      setPageType("End");
    }
  };

  const renderPreviousPage = () => {
    if (pageNumber === 4 && pageType === "Tests") {
      return;
    }
    setPageNumber(pageNumber - 1);
  };

  const TestsPageJump = (page) => {
    if (page) {
      setPageNumber(page);
    }
  };

  const forceEndTest = () => {
    setPageNumber(4);
    setPageType("End");
    setTestEnd(true);
  };

  const startReviewAnswers = () => {
    setPageNumber(4);
    setPageType("Tests");
  };

  const updatePlayTestSound = () => {
    setPlayTestSound(!playTestSound);
  };

  return (
    <div className="App">
      <Banner
        candidate={candidate}
        testType={testType}
        testTime={testTime}
        pageType={pageType}
        pageNumber={pageNumber}
        audioPath={examContent?.audio ?? ""}
        forceEndTest={forceEndTest}
        playTestSound={playTestSound}
      />
      {mainContent()}
    </div>
  );
};

export default App;
