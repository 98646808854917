import { find, propEq } from "ramda";

export const formatAnswer = (testPages, KEY, answers) => {
  let questionId = KEY.questionId;
  if (KEY.type === "checkboxes") {
    questionId += KEY.startQuestionId - KEY.questionId;
  }
  const answer = find(propEq("questionId", questionId))(answers);

  if (answer === undefined) {
    return "( Not answered. )";
  }

  let formattedAnswer = "";
  switch (KEY.type) {
    case "radios":
    case "checkboxes": {
      let selected = answer.answer.indexOf(true) + 1;
      if (KEY.type === "checkboxes") {
        selected = -1;
        let countDown = KEY.questionId - KEY.startQuestionId + 1;
        answer.answer.forEach((a, aIndex) => {
          if (!a || countDown === 0) {
            return;
          }

          countDown--;
          if (countDown > 0) {
            return;
          }
          selected = aIndex + 1;
        });
      }

      let findAnswer = find(propEq("id", questionId));
      let answerInContent = null;
      for (let page of testPages) {
        for (let formattedGroup of page.formattedGroups) {
          if (!formattedGroup) {
            continue;
          }
          answerInContent = findAnswer(formattedGroup.content);
          if (answerInContent) {
            break;
          }
        }
        if (answerInContent) {
          break;
        }
      }
      let description = find(propEq("id", selected))(answerInContent.answers);
      if (!description) {
        break;
      }
      formattedAnswer = description.content;
      break;
    }

    case "table":
    case "table inputs":
    case "table inputs two":
    case "table inputs with image": {
      let answersIndex = [];
      for (let page of testPages) {
        for (let formattedGroup of page.formattedGroups) {
          if (!formattedGroup) {
            continue;
          }
          for (let answerInContent of formattedGroup.content) {
            if (answerInContent.id !== questionId) {
              continue;
            }
            answersIndex = [...formattedGroup.answersIndex];
            break;
          }
          if (answersIndex.length > 0) {
            break;
          }
        }
        if (answersIndex.length > 0) {
          break;
        }
      }

      formattedAnswer = answersIndex[answer.answer - 1];
      break;
    }

    case "input":
    case "inputTexts in paragraphs":
    case "inputTexts in an image":
    case "inputTexts in one paragraph":
    case "dragNDrop":
    default:
      formattedAnswer = answer.answer;
      break;
  }

  return formattedAnswer;
};
