import React, { Fragment, useEffect, useState } from "react";
import { find, equals, propEq, filter, uniq } from "ramda";
import * as R from 'ramda';
import HtmlReactParser from "html-react-parser";
import Answers from "../../components/Answers";
import { parseListenDragDropQuestion, parseDragDropQuestion } from '../pageContents/Const';
import DropQuestion from "./DropQuestion";

const getQuestionSeqs = (targetPage) => {
  let seqs = [];
  if (targetPage.parsedContent) {
    seqs.push(...targetPage.parsedContent.map((q) => q.id));
  }
  targetPage.formattedGroups.forEach((formattedGroup) => {
    seqs.push(...formattedGroup.content.map((q) => q.id));
  });

  seqs = uniq(seqs);
  seqs.sort((a, b) => a - b);
  return seqs;
};

const TestPage = ({
  display,
  pageSeq,
  pages,
  page,
  testType,
  chosenQuestion,
  setChosenQuestion,
  answeredQuestion,
  setAnsweredQuestion,
  renderNextPage,
  renderPreviousPage,
  TestsPageJump,
  reviewChecked,
  setReviewChecked,
  reviewModeNode,
  setReviewModeNode,
  chooseQForCheckbox,
  setChooseQForCheckbox,
  storedAnswers,
  setStoredAnswers,
  writingAnswerNote,
  setWritingAnswerNote,
}) => {
  const [checkboxPages, setCheckboxPages] = useState([]);
  const [matchingHeadingPages, setMatchingHeadingPages] = useState([]);
  const [replacedText, setReplacedText] = useState("");

  useEffect(() => {
    const hasCheckbox = propEq("type", "checkboxes");
    const hasCheckboxPages = filter((filterPage) => {
      for (let group of filterPage.formattedGroups) {
        if (!hasCheckbox(group)) {
          continue;
        }
        return true;
      }
      return false;
    })(pages);
    setCheckboxPages(
      Object.values(hasCheckboxPages).map((_, pageIndex) => pageIndex + 1)
    );

    const hasMatchingHeading = propEq("questionType", "drop in answer part");
    const hasMatchingHeadingPages = filter(hasMatchingHeading, pages);
    setMatchingHeadingPages(
      Object.values(hasMatchingHeadingPages).map(
        (_, pageIndex) => pageIndex + 1
      )
    );
  }, [pages]);

  // 點開題目
  const toggleQuestion = (chosenId, targetPageSeq) => {
    if (targetPageSeq !== pageSeq) {
      TestsPageJump(targetPageSeq + 3);
    }

    setChosenQuestion(chosenId);
  };

  // 檢查題目
  const checkAnsweredQuestion = (questionId, answersStatus) => {
    const newAnsweredQuestion = [...answeredQuestion];
    const questionAnswered = answersStatus.filter(
      (checked) => checked === true
    );

    if (questionAnswered.length === 0) {
      newAnsweredQuestion[questionId - 1] = false;
    } else {
      newAnsweredQuestion[questionId - 1] = true;
    }
    setAnsweredQuestion(newAnsweredQuestion);
  };

  // 填答案
  const storeAnswers = (questionId, answer) => {
    let newStoredAnswers = storedAnswers.filter(
      (ans) => ans.questionId !== questionId
    );

    /* 拖拉類型的題目不能重複相同答案 */
    let sameDragSeqs = [];
    for (let p of pages) {
      if (!p.groups) {
        break;
      }
      for (let g of p.groups) {
        // console.log(g);
        if (!R.is(Array, g.questions) || g.type.split('-')[0] !== 'DRAG') {
          continue;
        }
        sameDragSeqs = (g.type === 'DRAG-Common' ? parseListenDragDropQuestion : parseDragDropQuestion)(g).content.map(c => c.id)
        if (!sameDragSeqs.includes(questionId)) {
          sameDragSeqs = [];
          continue;
        }
        break;
      }
      if (sameDragSeqs.length > 0) {
        break;
      }
    }

    if (sameDragSeqs.length > 0 && answer !== '') {
      // console.log(sameDragSeqs, answer);
      newStoredAnswers = newStoredAnswers.filter(
        (ans) => !sameDragSeqs.includes(ans.questionId) || ans.answer !== answer
      );
    }

    newStoredAnswers = [...newStoredAnswers, { questionId, answer }];

    // console.log(pages);
    // console.log(newStoredAnswers);
    setStoredAnswers(newStoredAnswers);
  };

  // 寫作填答
  const updateAnwerPerPage = (content, pageNum) => {
    const newWritingAnswerNote = [...writingAnswerNote];
    newWritingAnswerNote[pageNum - 1] = content;
    setWritingAnswerNote(newWritingAnswerNote);
  };

  const renderReviewNode = () => {
    const newReviewModeNode = [...reviewModeNode];
    const newReviewChecked = !reviewChecked;
    setReviewChecked(newReviewChecked);

    if (find(equals(pageSeq))(checkboxPages)) {
      if (newReviewChecked) {
        newReviewModeNode[chooseQForCheckbox - 1] = true;
      } else {
        newReviewModeNode[chooseQForCheckbox - 1] = false;
      }

      setReviewModeNode(newReviewModeNode);
    } else {
      if (newReviewChecked) {
        newReviewModeNode[chosenQuestion - 1] = true;
      } else {
        newReviewModeNode[chosenQuestion - 1] = false;
      }

      setReviewModeNode(newReviewModeNode);
    }
  };

  const renderNavAnswers = (navPage) => {
    const getItem = (questionSeq) => (
      <li key={questionSeq}>
        <button
          className={`btn btn-primary font-weight-bolder ${
            chosenQuestion === questionSeq ? "highlight" : ""
          } ${reviewModeNode[questionSeq - 1] ? "for-review" : ""} ${
            answeredQuestion[questionSeq - 1] ? "answered" : ""
          }`}
          onClick={() => {
            toggleQuestion(questionSeq, navPage.page);
            setReviewChecked(!!reviewModeNode[questionSeq - 1]);
          }}
        >
          {questionSeq}
        </button>
      </li>
    );

    return getQuestionSeqs(navPage).map((questionSeq) => {
      setTimeout(() => {
        document.querySelectorAll(".question-buttons").forEach((wrapper) => {
          const wrapperRect = wrapper.getBoundingClientRect();
          if (wrapperRect.x === 0 || wrapperRect.y === 0) {
            return;
          }

          const xRange = [wrapperRect.x, wrapperRect.x + wrapperRect.width];

          const highlight = wrapper.querySelector(".highlight");
          if (!highlight) {
            return;
          }

          const elRect = highlight.getBoundingClientRect();
          if (xRange[0] < elRect.x && xRange[1] > elRect.x) {
            return;
          }

          highlight.scrollIntoView({
            block: "nearest",
            inline: "center",
          });
        });
      }, 100);
      return getItem(questionSeq);
    });
  };

  const renderAllNavAnswers = () => {
    return pages.map((navPage, i) => {
      return (
        <Fragment key={i}>
          <li className="part-label-holder">
            <div className="btn btn-primary disabled part-label">
              {`Page ${i + 1}`}:
            </div>
          </li>
          {renderNavAnswers(navPage)}
        </Fragment>
      );
    });
  };

  const handleNextClick = () => {
    const seqs = getQuestionSeqs(page);
    const newChosenQuestion = chosenQuestion + 1;
    setChosenQuestion(newChosenQuestion);
    if (newChosenQuestion > seqs[seqs.length - 1]) {
      renderNextPage();
    }
  };

  const handlePreviousClick = () => {
    const seqs = getQuestionSeqs(page);
    const newChosenQuestion = chosenQuestion - 1;
    setChosenQuestion(newChosenQuestion);
    if (newChosenQuestion < seqs[0]) {
      renderPreviousPage();
    }
  };

  return (
    <div
      className="container-fluid px-lg-5 h-100"
      style={display ? {} : { display: "none" }}
    >
      <div className="row mx-lg-n5 h-100">
        <div className="test-container container-fluid px-lg-5">
          <div className="title">
            <h4>{page.title ?? `Page ${pageSeq}`}</h4>
            <p>{page.description}</p>
          </div>
          <div
            className={`content ${page.formattedGroups.map(
              (group) => group.className
            )} ${testType === "L" ? "listening-test" : ""} `}
          >
            {testType !== "L" && (
              <div className="question">
                {page.questionType !== "drop in answer part" ? (
                  HtmlReactParser(page.content)
                ) : (
                  <DropQuestion
                    questions={page.parsedContent}
                    preContent={page.preContent}
                    postContent={page.postContent}
                    className="drop-target"
                    chosenQuestion={chosenQuestion}
                    toggleQuestion={toggleQuestion}
                    checkAnsweredQuestion={checkAnsweredQuestion}
                    generateReplacedText={setReplacedText}
                    storeAnswers={storeAnswers}
                    storedAnswers={storedAnswers}
                  />
                )}
              </div>
            )}
            <div className="answer">
              {page.formattedGroups.map((group, groupIndex) => (
                <Answers
                  key={groupIndex}
                  answers={group}
                  chosenQuestion={chosenQuestion}
                  toggleQuestion={toggleQuestion}
                  checkAnsweredQuestion={checkAnsweredQuestion}
                  generateReplacedText={setReplacedText}
                  replacedText={replacedText}
                  storeAnswers={storeAnswers}
                  storedAnswers={storedAnswers}
                  writingAnswerNote={
                    writingAnswerNote[pageSeq - 1]
                      ? writingAnswerNote[pageSeq - 1]
                      : ""
                  }
                  updateAnwerPerPage={updateAnwerPerPage}
                  pageNumber={pageSeq}
                />
              ))}
            </div>
          </div>
          <div className="row question-navigation d-none d-lg-flex">
            <div className="col col-lg-1 col-xs-12 col-sm-6 order-2 order-lg-1 input-group">
              <button
                className="btn btn-block review"
                onClick={renderReviewNode}
              >
                <span
                  className={`check ${reviewChecked ? "checked" : ""}`}
                ></span>
                Review
              </button>
            </div>
            <div className="col col-lg-9 col-xs-12 order-1 order-xs-1 order-sm-1 question-nav">
              <ul className="question-buttons">{renderAllNavAnswers()}</ul>
            </div>
            <div
              className="col col-lg-2 col-xs-12 col-sm-6 order-3 order-xs-3 order-sm-3 arrows"
              data-total-questions="40"
            >
              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-primary prev"
                    onClick={handlePreviousClick}
                    disabled={chosenQuestion - 1 < 1}
                  >
                    <span className="arrow-left"></span>
                  </button>
                  <button
                    className="btn btn-primary next"
                    onClick={handleNextClick}
                  >
                    <span className="arrow-right"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestPage;
