import React, { Component } from "react";

class TextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.writingAnswerNote,
      wordCount: 0,
    };
    this.textareaRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.writingAnswerNote !== undefined) {
      const words = nextProps.writingAnswerNote
        .split(/\s+/)
        .filter((word) => word !== "");
      this.setState({
        content: nextProps.writingAnswerNote,
        wordCount: words.length,
      });
    } else {
      this.setState({ content: "", wordCount: 0 });
    }
  }

  wordCounter() {
    const words = this.state.content.split(/\s+/).filter((word) => word !== "");
    this.setState({ wordCount: words.length }, () => {
      if (this.state.wordCount === 0) {
        this.props.checkAnsweredQuestion(this.props.pageNumber, [false]);
      } else {
        this.props.checkAnsweredQuestion(this.props.pageNumber, [true]);
      }
    });
  }

  render() {
    return (
      <div className="text-area-container">
        <textarea
          ref={this.textareaRef}
          spellCheck="false"
          onChange={(event) =>
            this.setState({ content: event.target.value }, () => {
              this.wordCounter();
              this.props.updateAnwerPerPage(
                this.state.content,
                this.props.pageNumber
              );
            })
          }
          onKeyDown={(e) => {
            if (e.keyCode !== 9) {
              return;
            }
            e.preventDefault();

            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;

            const value = e.target.value;
            const self = this;
            this.setState(
              {
                content:
                  value.substring(0, start) + "\t" + value.substring(end),
              },
              () => {
                this.wordCounter();
                this.props.updateAnwerPerPage(
                  this.state.content,
                  this.props.pageNumber
                );

                self.textareaRef.current.selectionStart =
                  self.textareaRef.current.selectionEnd = start + 1;
                // console.log(this.refs);
                // this.refs.textarea.selectionStart =
                //   this.refs.textarea.selectionEnd = start + 1;
              }
            );
          }}
          value={this.state.content}
        />
        <p>
          Word Count: <span>{this.state.wordCount}</span>
        </p>
      </div>
    );
  }
}

export default TextArea;
