import React, { Component } from "react";
import Information from "./helpContents/Information";
import TestHelp from "./helpContents/TestHelp";
import TaskHelp from "./helpContents/TaskHelp";

class HelpButtonPopup extends Component {
  state = {
    showContentIndex: 2,
  };

  switchContent(index) {
    this.setState({ showContentIndex: index });
  }

  render() {
    const { pageNumber, toggleHelpPopup } = this.props;
    return (
      <div
        className="modal fade show"
        id="help-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="helpLabel"
        style={{
          display: "block",
          paddingRight: "17px",
        }}
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="helpLabel">
                Help
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggleHelpPopup}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs" id="help-modal-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.showContentIndex === 0 ? "active" : ""
                    }`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected="true"
                    onClick={() => this.switchContent(0)}
                    href="#Information"
                  >
                    Information
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.showContentIndex === 0 ? "active" : ""
                    }`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    onClick={() => this.switchContent(1)}
                    href="#TestHelp"
                  >
                    Test help
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.showContentIndex === 0 ? "active" : ""
                    }`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    onClick={() => this.switchContent(2)}
                    href="#TaskHelp"
                  >
                    Task help
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="help-modal-tab-content">
                <div className="tab-pane fade active show" role="tabpanel">
                  {this.state.showContentIndex === 0 && <Information />}
                  {this.state.showContentIndex === 1 && <TestHelp />}
                  {this.state.showContentIndex === 2 && (
                    <TaskHelp pageNumber={pageNumber} />
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={toggleHelpPopup}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HelpButtonPopup;

// <div className="modal fade show" id="help-modal" tabindex="-1" role="dialog" aria-labelledby="helpLabel" style="display: block; padding-right: 17px;" aria-modal="true">
// <div className="modal-dialog modal-lg" role="document">
// <div className="modal-content">
// <div className="modal-header">
// <h5 className="modal-title" id="helpLabel">Help</h5>
// <button type="button" className="close" data-dismiss="modal" aria-label="Close">
// <span aria-hidden="true">×</span>
// </button>
// </div>
// <div className="modal-body">
// <ul className="nav nav-tabs" id="help-modal-tabs" role="tablist">
// <li className="nav-item">
// <a className="nav-link active" id="help-information-tab" data-toggle="tab" href="#help-information" role="tab" aria-controls="help-information" aria-selected="true" onclick="$('#help-modal').modal('handleUpdate')">Information</a>
// </li>
// <li className="nav-item">
// <a className="nav-link" id="help-test-help-tab" data-toggle="tab" href="#help-test-help" role="tab" aria-controls="help-test-help" aria-selected="false" onclick="$('#help-modal').modal('handleUpdate')">Test help</a>
// </li>
// <li className="nav-item">
// <a className="nav-link" id="help-task-help-tab" data-toggle="tab" href="#help-task-help" role="tab" aria-controls="help-task-help" aria-selected="false" onclick="$('#help-modal').modal('handleUpdate')">Task help</a>
// </li>
// </ul>
// <div className="tab-content" id="help-modal-tab-content">
// <div className="tab-pane fade active show" id="help-information" role="tabpanel" aria-labelledby="help-information-tab">
// <h4>INSTRUCTIONS TO CANDIDATES</h4>
// <ul>
// <li>Answer all the questions.</li>
// <li>You can change your answers at any time during the test.</li>
// </ul>
// <h4>INFORMATION FOR CANDIDATES</h4>
// <ul>
// <li>There are 40 questions in this test.</li>
// <li>Each question carries one mark.</li>
// <li>There are four parts to the test.</li>
// <li>You will hear each part once.</li>
// <li>For each part of the test there will be time for you to look through the questions and
// time for you to check your answers.
// </li>
// </ul>
// </div>
// <div className="tab-pane fade" id="help-test-help" role="tabpanel" aria-labelledby="help-test-help-tab">
// <h4>At the top of the screen you can see:</h4>
// <img className="d-block img-sm" src="/img/help/c-info-name.png" alt="Candidate information">
// <p>Your name and candidate number.</p>
// <img className="d-block img-sm" src="/img/help/timer.png" alt="Timer">
// <p>A clock, which tells you how much time you have left. When you hover over the time you can
// see the seconds.</p>
// <hr>
// <img className="d-inline-block img-sm" src="/img/help/help-button.png" alt="Help button">
// <p className="d-inline-block">Click to view the help.</p>
// <br>
// <img className="d-inline-block img-sm" src="/img/help/hide-button.png" alt="Hide button">
// <p className="d-inline-block">Click to hide the screen content temporarily.</p>
// <hr>
// <h4>Volume</h4>
// <p>If you want to change the volume of the Listening test, click on the volume slider.</p>
// <img className="d-block img-sm" src="/img/help/volume.png" alt="Volume slider">
// <hr>
// <h4>Navigation</h4>
// <p>At the bottom of the screen you can see the navigation bar</p>
// <img className="d-block img-sm" src="/img/help/navigation.png" alt="Navigation bar">
// <p>Click on a number to go to that question.</p>
// <img className="d-inline-block img-sm" src="/img/help/next-q.png" alt="Next question">
// <p className="d-inline">Click to go to the next question</p>
// <br>
// <img className="d-inline-block img-sm" src="/img/help/prev-q.png" alt="Previous question">
// <p className="d-inline">Click to go to the previous question</p>
// <br>
// <img className="d-inline-block img-sm" src="/img/help/review.png" alt="Review">
// <p className="d-inline">Click if you want to look at this question again later. The question number in the navigation
// bar will turn into a circle.</p>
// <br>
// <img className="d-inline-block img-sm" src="/img/help/blue.png" alt="Blue highlighting">
// <p className="d-inline">The blue highlighting shows the question you are looking at.</p>
// <br>
// <img className="d-inline-block img-sm" src="/img/help/underline.png" alt="Underline">
// <p className="d-inline">The underlining shows that you have answered this question.</p>
// <br>
// <img className="d-inline-block img-sm" src="/img/help/circle.png" alt="Circle">
// <p className="d-inline">The circle shows that you want to look at this question again (marked for review).</p>
// <br>
// <img className="d-inline-block img-sm" src="/img/help/black.png" alt="Black">
// <p className="d-inline">The black highlighting shows that you have not answered the question.</p>
// <hr>
// <h4>Highlighting</h4>
// <p className="">To highlight something in the test:</p>
// <p className="bold">Select the text you want to highlight using the mouse.</p>
// <p className="bold">Right click over the text.</p>
// <img className="d-block img-md" src="/img/help/context-menu.png" alt="Context menu">
// <br>
// <img src="/img/help/menu-highlight.png" alt="Menu item - highlight" className="d-inline-block img-xs">
// <p className="d-inline">Click to highlight the text you have selected.</p>
// <br>
// <img src="/img/help/menu-notes.png" alt="Menu item - notes" className="d-inline-block img-xs">
// <p className="d-inline">Click to highlight the text you have selected and to add notes about what you have highlighted.</p>
// <br>
// <br>
// <h4>Notes</h4>
// <p className="d-block">To close the notes click on X in the top right. To view the notes right-click on the highlighted text (anything you write in Notes will be deleted at the end of the test).</p>
// <img src="/img/help/notes.png" alt="Notes" className="d-block img-md">
// <br>
// <p className="d-block">You can locate those areas of highlighted text containing notes by hovering over each highlighted text. If a small orange box appears the highlighted text contains notes.</p>
// <img src="/img/help/highlighted-rows.png" alt="Highlighted text" className="d-block img-md">
// <br>
// <p className="d-block">To clear your highlighting right click on the highlighted text.</p>
// <img src="/img/help/context-menu-clear.png" alt="Context menu - clear" className="d-block img-md">
// <br>
// <img src="/img/help/clear.png" alt="Menu item - clear" className="d-inline-block img-xs">
// <p className="d-inline">Click to clear the highlighting. This will also clear any notes you have made.</p>
// <br>
// <img src="/img/help/clear-all.png" alt="Menu item - clear all" className="d-inline-block img-xs">
// <p className="d-inline">Click to clear all highlighting including notes.</p>
// </div>
// <div className="tab-pane fade" id="help-task-help" role="tabpanel" aria-labelledby="help-task-help-tab">
// <p>To choose a question either click on the question number at the bottom of the screen or click on the question.</p>
// <hr>
// <h4>Gap fill questions</h4>
// <p>To answer some questions, you need to write words or numbers in a gap. To answer these
// questions, click in the gap and write your answer. Some questions may ask you to write your
// answer in a table, flow-chart or diagram.</p>
// <img src="/img/help/1-listening-gap-fill.png" alt="Gap fill questions" className="d-block img-lg">
// <hr>
// <h4>Drag and drop questions</h4>
// <p>To answer a question, click on the answer on the right</p>
// <img src="/img/help/2-listening-drag-and-drop-1.png" alt="Drag and drop questions" className="d-block img-lg">
// <p>and move it into the gap on the left.</p>
// <img src="/img/help/3-listening-drag-and-drop-2.png" alt="Drag and drop questions" className="d-block img-lg">
// <p>If you want to change your answer, move another answer into the gap.</p>
// <p>If you want to leave the question unanswered, move the answer back.</p>
// <p>Don't forget! You may need to scroll to see all the questions and answers.</p>
// <hr>
// <h4>Multiple choice questions</h4>
// <p>Click on the answer you think is right.</p>
// <p>If you change your mind, click on a different answer.</p>
// <p>If you want to leave the question unanswered, click on your answer again.</p>
// <img src="/img/help/4-listening-mc-questions.png" alt="Multiple choice questions" className="d-block img-lg">
// <hr>
// <h4>Multiple choice questions where there is more than one answer</h4>
// <p>Some questions may ask you to choose two or three answers. Make sure you read the instructions and questions carefully.</p>
// <img src="/img/help/5-listening-mc-more-than-one.png" alt="Multiple choice questions with more than one answer" className="d-block img-lg">
// <p>Click on the answers you think are right.</p>
// <p>If you change your mind, click on a different answer.</p>
// <p>If you want to leave the question unanswered, click on your answer again.</p>
// <hr>
// <h4>Matching questions using a table</h4>
// <p>To select a question, click on the question number in the table. The column will become shaded.</p>
// <img src="/img/help/6-listening-match-table.png" alt="Matching questions using a table" className="d-block img-lg">
// <p>Select the correct option by clicking on a space in the table. A tick will appear.</p>
// <p>If you want to change your answer, click on another space.</p>
// <p>If you want to leave the question unanswered, click on the space again.</p>
// <p>Sometimes you may choose an answer more than once. Make sure you read the instructions and questions carefully.</p>
// </div>
// </div>
// </div>
// <div className="modal-footer">
// <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
// </div>
// </div>
// </div>
// </div>
