import React from "react";
import TextInput from "./TextInput";

const MultiInputsInImage = ({
  className,
  style,
  content,
  src,
  toggleQuestion,
  chosenQuestionId,
  checkAnsweredQuestion,
  storeAnswers,
  storedAnswers,
}) => {
  return (
    <div className={`multiple-inputs-container ${className}`} style={style}>
      <img style={{ width: "100%" }} src={src} alt="question" />
      {content.map((p) => (
        <TextInput
          key={p.id}
          id={p.id}
          toggleQuestion={toggleQuestion}
          chosenQuestionId={chosenQuestionId}
          checkAnsweredQuestion={checkAnsweredQuestion}
          storeAnswers={storeAnswers}
          storedAnswers={storedAnswers}
          style={p.style}
        />
      ))}
    </div>
  );
};

export default MultiInputsInImage;
