import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import DropBoxEmpty from "./DropBoxEmpty";
import DropBoxFull from "./DropBoxFull";

const DropContents = (props) => {
  const {
    content,
    postContent,
    className,
    toggleQuestion,
    chosenQuestionId,
    checkAnsweredQuestion,
    dragItems,
    generateReplacedText,
    replacedText,
    storeAnswers,
    storedAnswers,
  } = props;

  const [fullContent, setFullContent] = useState(null);

  useEffect(() => {
    let htmlString = "";
    content.forEach((p) => {
      htmlString += `${p.answers[0] ?? ""}<question>${p.id}</question>${
        p.answers[1] ?? ""
      }`;
    });
    if (postContent) {
      htmlString += postContent;
    }
    setFullContent(htmlString);
  }, [content, postContent]);

  return (
    <div className={`drop-content-container ${className}`} key={JSON.stringify(storedAnswers)}>
      {fullContent &&
        ReactHtmlParser(fullContent, {
          transform: (node, index) => {
            if (node.type === "tag" && node.name === "question") {
              return (
                <DropBoxEmpty
                  key={`${className}${node.children[0].data}`}
                  id={parseInt(node.children[0].data)}
                  toggleQuestion={toggleQuestion}
                  chosenQuestionId={chosenQuestionId}
                  checkAnsweredQuestion={checkAnsweredQuestion}
                  generateReplacedText={generateReplacedText}
                  storeAnswers={storeAnswers}
                  storedAnswers={storedAnswers}
                />
              );
            }
          },
        })}
      <DropBoxFull
        dragItems={dragItems}
        replacedText={replacedText}
        storedAnswers={storedAnswers}
      />
    </div>
  );
};

export default DropContents;
