import React, { Component, Fragment } from "react";
import * as R from "ramda";
import axios from "axios";
import { formatAnswer } from "../../utils/formatAnswer";

class TestResult extends Component {
  state = {
    errorContent: "",
  };

  constructor(props) {
    super(props);
    this.count = 0;
    this.score = 0;
    this.level = "";
    this.results = [];
    this.errorContent = "";

    try {
      const qustionIdSort = R.sortWith([R.ascend(R.prop("questionId"))]);

      let KEYS = qustionIdSort(this.props.answerKey);
      let answers = qustionIdSort(this.props.storedAnswers);
      KEYS.forEach((KEY) => this.saveResults(KEY, answers));
      this.calculateScore();

      const formatedAnswers = [];
      KEYS.forEach((KEY) => {
        const questionId = KEY.questionId;
        const answer = R.find(R.propEq("questionId", questionId))(answers);
        const formatedAnswer = formatAnswer(this.props.testPages, KEY, answers);
        if (answer === undefined) {
          formatedAnswers.push({
            questionId,
            answer: formatedAnswer,
          });
          return;
        }

        formatedAnswers.push({
          ...answer,
          answer: formatedAnswer,
        });
      });

      const url =
        process.env.REACT_APP_UPDATE_EXAMDETAIL +
        "/" +
        this.props.candidate.examDetailId;
      const params = {
        correct_count: this.count,
        score: this.score,
        stored_answers: JSON.stringify(formatedAnswers),
      };

      axios
        .post(`${url}`, params)
        .then((res) => {
          const data = res.data;
          if (!data.success) {
            console.log(data);
          }
        })
        .catch((e) => {
          this.setState({
            errorContent: `${e.toString()}：${JSON.stringify(
              this.props.storedAnswers
            )}`,
          });
        });
    } catch (e) {
      this.setState({
        errorContent: `${e.toString()}：${JSON.stringify(
          this.props.storedAnswers
        )}`,
      });
    }
  }

  state = {
    isSaved: false,
  };

  saveResults(KEY, answers) {
    let questionId = KEY.questionId;
    if (KEY.type === "checkboxes") {
      questionId += KEY.startQuestionId - KEY.questionId;
    }
    const answer = R.find(R.propEq("questionId", questionId))(answers);
    const id = questionId.toString().padStart(2, "0");

    if (answer === undefined) {
      this.results.push({
        questionId: id,
        status: "empty",
        formattedAnswer: "( Not answered. )",
      });
      return;
    }

    const formattedAnswer = formatAnswer(this.props.testPages, KEY, answers);
    // console.log(formattedAnswer);

    let userAnswer = answer.answer;
    let actualAnswers =
      R.type(KEY.answer) === "Array" ? KEY.answer : [KEY.answer];
    if (
      this.props.testType === "L" &&
      KEY.type !== "radios" &&
      userAnswer.toLowerCase
    ) {
      userAnswer = userAnswer.toLowerCase();
      actualAnswers = actualAnswers.map((a) => a.toLowerCase());
    }

    const correct = {
      questionId: id,
      status: "correct",
      formattedAnswer: formattedAnswer,
    };
    const wrong = {
      questionId: id,
      status: "wrong",
      formattedAnswer: formattedAnswer,
    };

    if (KEY.type === "radios") {
      if (R.equals(answer.answer, KEY.answer)) {
        this.count++;
        this.results.push(correct);
      } else {
        this.results.push(wrong);
      }
    } else if (KEY.type === "checkboxes") {
      const answerIndexes = [];
      if (answer.answer) {
        answer.answer.forEach((checked, index) => {
          if (!checked) {
            return;
          }

          answerIndexes.push(index + 1);
        });
      }
      const currentAnswer =
        answerIndexes[KEY.questionId - KEY.startQuestionId] ?? -1;

      if (KEY.answer.includes(currentAnswer)) {
        this.count++;
        this.results.push(correct);
      } else {
        this.results.push(wrong);
      }
    } else {
      if (R.find(R.equals(userAnswer))(actualAnswers)) {
        this.count++;
        this.results.push(correct);
      } else {
        this.results.push(wrong);
      }
    }

    return;
  }

  calculateScore() {
    let count = this.count;
    let score = 0;
    let level = "Did not attempt the test";
    if (count >= 39) {
      score = 9;
      level = "Expert user";
    } else if (count >= 38) {
      score = 8.5;
      level = "Very good user";
    } else if (count >= 35) {
      score = 8;
      level = "Very good user";
    } else if (count >= 32) {
      score = 7.5;
      level = "Good user";
    } else if (count >= 30) {
      score = 7;
      level = "Good user";
    } else if (count >= 27) {
      score = 6.5;
      level = "Competent user";
    } else if (count >= 23) {
      score = 6;
      level = "Competent user";
    } else if (count >= 19) {
      score = 5.5;
      level = "Modest user";
    } else if (count >= 15) {
      score = 5;
      level = "Modest user";
    } else if (count >= 13) {
      score = 4.5;
      level = "Limited user";
    } else if (count >= 10) {
      score = 4;
      level = "Limited user";
    } else if (count >= 6) {
      score = 3.5;
      level = "Extremely limited user";
    } else if (count >= 4) {
      score = 3;
      level = "Extremely limited user";
    } else if (count > 0 && count < 4) {
      score = 2;
      level = "Intermittent user";
    }

    this.score = score;
    this.level = level;
  }

  renderResultContent(status, id, answer) {
    return (
      <li className={status}>
        {id}. <span>{answer}</span>
      </li>
    );
  }

  render() {
    return (
      <div className="test-result-container">
        {this.state.errorContent && (
          <Fragment>
            <p style={{ color: "red !important" }}>
              Error! Sorry but something goes wrong.
            </p>
            <p style={{ color: "red !important" }}>
              Copy the following text to website owner.
            </p>
            <p>{this.state.errorContent}</p>
          </Fragment>
        )}
        <h1>Test Result</h1>
        <h3>
          <div>
            <b>{this.score}</b>: {this.level}
          </div>
        </h3>
        <ul>
          {this.results.map((result) =>
            this.renderResultContent(
              result.status,
              result.questionId,
              result.formattedAnswer
            )
          )}
        </ul>
      </div>
    );
  }
}

export default TestResult;
