export const INTROS = [
  {
    testType: "R",
    title: "IELTS Academic Reading",
    time: "1 hour",
    instructions: [
      "Answer <b>all</b> the questions.",
      "You can change your answers at any time during the test.",
    ],
    information: [
      "There are 40 questions in this test.",
      "Each question carries one mark.",
      "The test clock will show you when there are 10 minutes and 5 minutes remaining.",
    ],
    message: "Do not click 'Start test' until you are told to do so.",
    btnText: "Start test",
  },
  {
    testType: "L",
    title: "IELTS Listening",
    time: "Approximately 30 minutes",
    instructions: [
      "Answer <b>all</b> the questions.",
      "You can change your answers at any time during the test.",
    ],
    information: [
      "There are 40 questions in this test.",
      "Each question carries one mark.",
      "There are four parts to the test.",
      "You will hear each part once.",
      "For each part of the test there will be time for you to look through the questions and time for you to check your answers.",
    ],
    message: "Do not click 'Start test' until you are told to do so.",
    btnText: "Start test",
  },
  {
    testType: "W",
    title: "IELTS Academic Writing",
    time: "1 hour",
    instructions: [
      "Answer <b>both</b> parts.",
      "You can change your answers at any time during the test.",
    ],
    information: [
      "There are two parts in this test.",
      "Part 2 contributes twice as much as Part 1 to the writing score.",
      "The test clock will show you when there are 10 minutes and 5 minutes remaining.",
    ],
    message: "Do not click 'Start test' until you are told to do so.",
    btnText: "Start test",
  },
];
