import React from "react";
import Button from "../../components/Button";
import Hint from "../../components/Hint";

const ConfirmDetails = ({ candidate, handleClick }) => {
  return (
    <div className="confirm-container">
      <h1>Confirm your details</h1>
      <div>
        <p>
          Name:{" "}
          <b>
            <i>{candidate.name}</i>
          </b>
        </p>
        {candidate.name_ch && (
          <p>
            姓名:{" "}
            <b>
              <i>{candidate.name_ch}</i>
            </b>
          </p>
        )}
        <p>
          Date of birth:{" "}
          <b>
            <i>{candidate.dateOfBirth}</i>
          </b>
        </p>
        <p>
          Candidate number:{" "}
          <b>
            <i>{candidate.candidateNumber}</i>
          </b>
        </p>
        <p className="examiner-number">
          Examiner number:{" "}
          <b>
            <i>{candidate.examNumber}</i>
          </b>
        </p>
        <Hint Message="If your details are not correct, please inform the invigilator." />
        <Button btnText="My details are correct" handleClick={handleClick} />
      </div>
    </div>
  );
};

export default ConfirmDetails;
