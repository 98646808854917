import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Cover from "./assets/password-cover.svg";
import Panel from "./assets/password-panel.svg";
import ConfirmButton from "./assets/password-confirm-button.svg";
import WhiteLogo from "./assets/white-logo.svg";

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 100%;
  background-image: url(${Cover});
  background-size: 100%;
  background-repeat: repeat-y;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30vh 0;
  .logo {
    height: 100%;
    padding: 0 5rem;
  }
  .panel {
    height: 100%;
    padding: 0 5rem;
    position: relative;
    &--cover {
      height: 100%;
    }
    > input {
      font-size: 2rem;
      text-align: center;
      background: rgba(0, 0, 0, 0);
      position: absolute;
      transform: translate(-50%, -50%);
      top: 49%;
      left: 50%;
      width: 40%;
      height: 12%;
      border: 0px;
      :focus {
        outline: none;
        border: 0px;
      }
    }
    .wrong-password-notice {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 60%;
      left: 50%;
      color: red;
    }
    .confirm-button {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 75%;
      left: 70%;
      width: 8rem;
      cursor: pointer;
    }
  }
`;

const PasswordInput = ({ urlKey, handleLoading }) => {
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  // useEffect(() => {
  //   const url = process.env.REACT_APP_GET_EXAM_BY_URLKEY + "/" + urlKey;
  //   axios.post(`${url}`, { password: "EIMNU5U4" }).then((res) => {
  //     const data = res.data;
  //     if (data.success) {
  //       console.log(data);
  //       handleLoading(
  //         {
  //           ...data.candidate,
  //         },
  //         {
  //           ...data.practice_test,
  //           pages: JSON.parse(data.practice_test.pages),
  //           answers: JSON.parse(data.practice_test.answers),
  //         }
  //       );
  //     } else {
  //       setShowError(true);
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [urlKey]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    const url = process.env.REACT_APP_GET_EXAM_BY_URLKEY + "/" + urlKey;
    axios.post(`${url}`, { password }).then((res) => {
      const data = res.data;
      if (data.success) {
        console.log(data);
        handleLoading(
          {
            ...data.candidate,
          },
          {
            ...data.practice_test,
            pages: JSON.parse(data.practice_test.pages),
            answers: JSON.parse(data.practice_test.answers),
          }
        );
      } else {
        setShowError(true);
      }
    });
  };

  return (
    <div className="main-content-container">
      <StyledDiv>
        <img className="logo" src={WhiteLogo} alt="logo" />
        <div className="panel">
          <img className="panel--cover" src={Panel} alt="panel" />
          <input
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
          />
          {showError && (
            <div className="wrong-password-notice">
              <i>Wrong Password</i>
            </div>
          )}
          <img
            className="confirm-button"
            src={ConfirmButton}
            alt="confirm-button"
            onClick={handleSubmit}
          />
        </div>
      </StyledDiv>
      <form className="password-form" onSubmit={handleSubmit}>
        <input type="password" name="password" onChange={handleChange} />
        {showError && (
          <div className="wrong-password-notice">
            <i>Wrong Password</i>
          </div>
        )}
        <div>
          <button type="submit">Enter Exam</button>
        </div>
      </form>
    </div>
  );
};

export default PasswordInput;
