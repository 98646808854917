import React, { Component } from "react";
import IELTSAcademicReadingChoiceAccordion from "../../../assets/images/popups/taskhelp/IELTS-AcademicReading-ChoiceAccordion.png";
import IELTSAcademicReadingMultiChoice from "../../../assets/images/popups/taskhelp/IELTS-AcademicReading-MultiChoice.png";
import IELTSAcademicReadingGapFill from "../../../assets/images/popups/taskhelp/IELTS-AcademicReading-GapFill.png";
import IELTSAcademicReadingTableMatch1 from "../../../assets/images/popups/taskhelp/IELTS-AcademicReading-TableMatch1.png";
import IELTSAcademicReadingTableMatch2 from "../../../assets/images/popups/taskhelp/IELTS-AcademicReading-TableMatch2.png";
import IELTSAcademicReadingDragDrop1 from "../../../assets/images/popups/taskhelp/IELTS-AcademicReading-DragDrop1.png";
import IELTSAcademicReadingDragDrop2 from "../../../assets/images/popups/taskhelp/IELTS-AcademicReading-DragDrop2.png";

class TaskHelp extends Component {
  componentDidMount() {
    if (this.props.pageNumber) {
      const targetId = this.generateTarget(this.props.pageNumber);
      const el = document.getElementById(targetId);
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pageNumber !== this.props.pageNumber) {
      const targetId = this.generateTarget(this.props.pageNumber);
      const el = document.getElementById(targetId);
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  generateTarget(pageNumber) {
    let targetId;
    if (pageNumber === 1 || pageNumber === 3) {
      targetId = "task-help-1";
    } else if (pageNumber === 2) {
      targetId = "task-help-2";
    } else if (
      pageNumber === 4 ||
      pageNumber === 8 ||
      pageNumber === 9 ||
      pageNumber === 10
    ) {
      targetId = "task-help-3";
    } else if (pageNumber === 5 || pageNumber === 6 || pageNumber === 7) {
      targetId = "task-help-4";
    }

    return targetId;
  }

  render() {
    return (
      <div className="task-help-container">
        <p>
          To choose a question either click on the question number at the bottom
          of the screen or click on the question.
        </p>
        <p id="task-help-1" className="title">
          <b>Multiple choice questions in an accordion</b>
        </p>
        <p>Choose your question by clicking on it.</p>
        <img
          src={IELTSAcademicReadingChoiceAccordion}
          height="280"
          alt="IELTS Academic Reading Choice Accordion"
        />
        <p>Click on the answer you think is right.</p>
        <p>If you change your mind, click on a different answer.</p>
        <p>
          If you want to leave the question unanswered, click on your answer
          again.
        </p>
        <p id="task-help-2" className="title">
          <b>Multiple choice questions where there is more than one answer</b>
        </p>
        <p>
          Some questions may ask you to select two or three options. Please make
          sure you read the instructions and questions carefully.
        </p>
        <img
          src={IELTSAcademicReadingMultiChoice}
          height="224"
          alt="IELTS Academic Reading MultiChoice"
        />
        <p>Click on the answers you think are right.</p>
        <p>If you change your mind, click on a different answer.</p>
        <p>
          If you want to leave the question unanswered, click on your answer
          again.
        </p>
        <p id="task-help-3" className="title">
          <b>Gap fill questions</b>
        </p>
        <p>
          To answer a question, click in the gap and write your answer. Some
          questions may ask you to write your answer in a table, flow-chart or
          diagram.
        </p>
        <img
          src={IELTSAcademicReadingGapFill}
          height="127"
          alt="IELTS Academic Reading GapFill"
        />
        <p className="title">
          <b>Matching questions using a table</b>
        </p>
        <p>
          To select a question, click on the question number in the table. The
          column will become shaded.
        </p>
        <img
          src={IELTSAcademicReadingTableMatch1}
          height="342"
          alt="IELTS Academic Reading Table Match1"
        />
        <p>
          Select the correct option by clicking on a space in the table. A tick
          will appear.
        </p>
        <img
          src={IELTSAcademicReadingTableMatch2}
          height="342"
          alt="IELTS Academic Reading Table Match1"
        />
        <p>If you want to change your answer, click on another space.</p>
        <p>
          If you want to leave the question unanswered, click on the space
          again.
        </p>
        <p id="task-help-4" className="title">
          <b>Drag and drop questions</b>
        </p>
        <img
          src={IELTSAcademicReadingDragDrop1}
          height="240"
          alt="IELTS Academic Reading DragDrop1"
        />
        <p>
          To answer a question, click on the answer and move it into the gap.
        </p>
        <img
          src={IELTSAcademicReadingDragDrop2}
          height="182"
          alt="IELTS Academic Reading DragDrop2"
        />
        <p>
          If you want to change your answer, move another answer into the gap.
        </p>
        <p>
          If you want to leave the question unanswered, move the answer back.
        </p>
        <p>
          Don&#39;t forget! You may need to scroll to see all the Reading text
          and all the questions.
        </p>
      </div>
    );
  }
}

export default TaskHelp;
