import React, { Fragment, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { sortWith, ascend, prop, find, propEq } from "ramda";
import { formatAnswer } from "../../utils/formatAnswer";
import Button from "../../components/Button";

const ConfirmFinish = ({
  testType,
  answerKey,
  storedAnswers,
  testPages,
  candidate,
  handleClick,
  testEnd,
  forceEndTest,
}) => {
  const [errorContent, setErrorContent] = useState("");

  const storeAnswer = useCallback(() => {
    const formatedAnswers = [];
    try {
      if (testType !== "W") {
        const qustionIdSort = sortWith([ascend(prop("questionId"))]);
        const KEYS = qustionIdSort(answerKey);
        const answers = qustionIdSort(storedAnswers);

        KEYS.forEach((KEY) => {
          const questionId = KEY.questionId;
          const answer = find(propEq("questionId", questionId))(answers);
          const formatedAnswer = formatAnswer(testPages, KEY, answers);
          if (answer === undefined) {
            formatedAnswers.push({
              questionId,
              answer: formatedAnswer,
            });
            return;
          }

          formatedAnswers.push({
            ...answer,
            answer: formatedAnswer,
          });
        });
      } else {
        storedAnswers.forEach((answer) => formatedAnswers.push(answer));
      }
    } catch (e) {
      console.log(e);
      setErrorContent(`${e.toString()}：${JSON.stringify(storedAnswers)}`);
      return;
    }

    const url =
      process.env.REACT_APP_STORE_ANSWERS + "/" + candidate.examDetailId;
    const params = {
      stored_answers: JSON.stringify(formatedAnswers),
    };

    axios
      .post(`${url}`, params)
      .then((res) => {
        const data = res.data;
        if (!data.success) {
          console.log(data);
        }
      })
      .catch((e) => {
        console.log(e);
        setErrorContent(`${e.toString()}：${JSON.stringify(formatedAnswers)}`);
      });
  }, [answerKey, candidate.examDetailId, storedAnswers, testPages, testType]);

  useEffect(() => {
    storeAnswer();
  }, [storeAnswer]);

  useEffect(() => {});

  return (
    <div className="confirm-finish-container">
      {errorContent && (
        <Fragment>
          <p style={{ color: "red !important" }}>
            Error! Sorry but something goes wrong.
          </p>
          <p style={{ color: "red !important" }}>
            Copy the following text to website owner.
          </p>
          <p>{errorContent}</p>
        </Fragment>
      )}
      <h1>Test ended</h1>
      <div>
        <p>Your test has finished.</p>
        <p>All of your answers have been stored.</p>
        <p>Please wait for further instructions.</p>
        {!testEnd && (
          <Button btnText="Review answers" handleClick={handleClick} />
        )}
        <br />
        {!testEnd && testType !== "W" && (
          <Button
            btnText="End Test"
            handleClick={forceEndTest}
            className="notice"
          />
        )}
      </div>
    </div>
  );
};
export default ConfirmFinish;
