import React from "react";

const Button = ({ btnText, handleClick, className = "normal" }) => {
  return (
    <button onClick={handleClick} className={className}>
      {btnText}
    </button>
  );
};

export default Button;
